<template>
  <div class="home">
    <Head :hId="2"></Head>
    <div class="main">
      <div class="content">
        <ul class="list" v-if="total">
          <li v-for="item in list" :key="item.id">
            <a :href="'/newDetail/?id=' + item.id">
              <img :src="item.picture" alt="" />
              <div class="text">
                <h4>{{ item.title }}</h4>
                <p>{{ unEscapeHTML(item.content.length>220?item.content.slice(0, 220)+'...': item.content)}}</p>
                <div class="time">
                  <span>{{ item.viewCount }}</span>
                  <span>{{ item.createTime }}发布</span>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <el-empty style="padding: 60px 0 300px" v-if="!total" :image-size="100"></el-empty>
        <el-footer class="listPage" v-if="total > 0">
          <el-pagination
            :total="total"
            :current-page.sync="newForm.pageNum"
            :page-size.sync="newForm.pageSize"
            layout="total, prev, pager, next, jumper"
            @size-change="getNewList"
            @current-change="getNewList"
          ></el-pagination>
        </el-footer>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { newList } from "@/api/new";
export default {
  name: "Recruit",
  data() {
    return {
      list: [],
      total: 0,
      newForm: {
        pageNum: 1,
        pageSize: 15,
        type: 0,
      },
      userInfo: '',
    };
  },
  components: {
    Head,
    Footer,
  },
  mounted() {
    const userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
      if(this.userInfo.loginDO.loginType === 2){
        this.newForm.type = 1
      }
    }
    this.getNewList();
  },
  methods: {
    unEscapeHTML(html) {
        html= "" +html;
        return html.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&").replace(/&quot;/g, '"').replace(/&apos;/g, "'").replace(/<[^>]+>/g,"").replace(/&nbsp;/g,'');
    },
    getNewList() {
      if(this.userInfo && this.userInfo.loginDO.loginType === 2){
        this.newForm.type = 1
      }
      newList(this.newForm).then((res) => {
        this.list = res.rows;
        this.total = res.total
      });
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  min-height: calc(100vh - 278px);
}
.list {
  overflow: hidden;
  margin-top: 20px;
  li {
    padding: 16px;
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    img {
      float: left;
      width: 245px;
      height: 184px;
    }
    .text {
      overflow: hidden;
      padding-left: 24px;
      h4 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
      }
      p {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
      .time {
        margin-top: 18px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        span {
          margin-right: 40px;
          background: transparent url("../../assets/img/news/icon-eys.png")
            no-repeat left center;
          padding-left: 22px;
          background-size: 16px;
          &:last-child {
            background-size: 18px !important;
            background: transparent url("../../assets/img/news/icon-yd.png")
              no-repeat left center;
          }
        }
      }
    }
  }
}
</style>
